import request from '@/utils/request'

// 获取洗涤明细数据
export const queryStoreWashingDetails = (params)=>{
    return request({
        method:'GET',
        url:'order/orderclothes/queryStoreWashingDetails',
        params
    })
}
