<template>
  <div class="userView content-index">
    <div class="topView">
      <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="洗涤明细"
      ></el-page-header>
      <div class="contentView">
        <div class="filterView">
          <div class="dateView">
            <el-date-picker
                class="setWidth"
                v-model="times"
                type="daterange"
                value-format="yyyy-MM-dd"
                @change="selectTimes"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </div>
          <div class="keywordView">
            <el-button type="primary"   @click="queryData">确认</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="contentView">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">
            {{scope.$index+1}}
          </template>
        </el-table-column>
        <el-table-column prop="storeName" label="门店名称" width="130" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="orderNumber" label="订单编号" width="130" align="center"></el-table-column>
        <el-table-column prop="clothesNum" label="衣物号" width="140" align="center"></el-table-column>
        <el-table-column prop="outClothesNumber" label="外部衣物号" width="100" align="center"></el-table-column>
        <el-table-column prop="userName" label="用户姓名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="userPhone" label="联系电话" width="120" align="center">
           <template slot-scope="scope">
            <span v-hidephone>{{ scope.row.userPhone }}</span>
             </template>
        </el-table-column>
        <el-table-column prop="clothesName" label="衣物名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="categoryName" label="衣物类型" align="center"></el-table-column>
        <el-table-column prop="serverName" label="洗涤服务" align="center"></el-table-column>
        <el-table-column prop="clothesPrice" label="支付金额" align="center"></el-table-column>
        <el-table-column prop="factoryName" label="工厂" width="120" align="center"></el-table-column>
        <el-table-column prop="payWayStr" label="支付方式" align="center"></el-table-column>
        <el-table-column prop="payStatus" label="支付状态" align="center"></el-table-column>
        <el-table-column prop="paymentTime" label="支付时间" width="160" align="center"></el-table-column>
        <el-table-column prop="recentStatus" label="衣物状态" align="center"></el-table-column>
        <el-table-column prop="sendWashTime" label="送洗时间" width="160" align="center"></el-table-column>
        <el-table-column prop="hangUpTime" label="上挂时间" width="160" align="center"></el-table-column>
        <el-table-column prop="isModify" label="是否改价" align="center"></el-table-column>
        <el-table-column prop="modifyReason" label="改价原因" width="100" align="center"></el-table-column>
        <el-table-column prop="isCoupon" label="是否使用优惠券" width="160" align="center"></el-table-column>
        <el-table-column prop="squareNum" label="平方数" align="center"></el-table-column>
      </el-table>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="queryInfo.current"
              :page-size="queryInfo.size"
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
import {queryStoreWashingDetails} from "@/api/finance";

export default {
  name: 'washdetail',
  data() {
    return {
      queryDisadle: true,
      loading: false,
      exportLoading: false,
      visible: false,
      queryInfo: {
        size: 10,
        current: 1,
        storeIdList: [],
        clothesId: '',
        beginTime: '',
        endTime: ''
      },
      total: 0,
      tableData: [],
      times: [],
      options: [],
      value: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;//如果不包括今天。就是return time.getTime() > Date.now() - 24*3600*1000;
        },
      },
      radio: '1'
    }
  },
  created() {
  },
  methods: {
    selectTimes() {
      if (this.times) {
        this.queryInfo.beginTime = this.times[0]
        this.queryInfo.endTime = this.times[1]
      } else {
        this.queryInfo.beginTime = ''
        this.queryInfo.endTime = ''
      }
    },
    queryData() {
      this.queryInfo.current = 1
      this.getPageInfo()
    },
    async getPageInfo() {
      this.loading = true;
      const params = new URLSearchParams()
      params.append('beginTime', this.queryInfo.beginTime)
      params.append('endTime', this.queryInfo.endTime)
      params.append('size', this.queryInfo.size)
      params.append('current', this.queryInfo.current)
      const {data: res} = await queryStoreWashingDetails(params)
      this.loading = false;
      if (res.code != 0) return this.$message.error(res.msg)
      this.tableData = res.data.orderList
      this.total = res.data.total
    },
    handleSizeChange(val) {
      this.queryInfo.size = val;
      this.getPageInfo()
    },
    handleCurrentChange(val) {
      this.queryInfo.current = val;
      this.getPageInfo()
    },
    selectClothes() {
      this.queryInfo.clothesId = this.value[1] ? this.value[1] : ''
      console.log(this.queryInfo.clothesId);
    },
  }
}
</script>

<style scoped lang="scss">
.pagination {
  text-align: right;
  padding: 15px 0;
}

.userView {
  margin: 0;
  padding: 20px;
  overflow: auto;

  > .topView {

    > .contentView {
      position: relative;
      padding: 0 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 35px;
        width: 120px;
        font-family: PingFangSC-Semibold;
       font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .filterView {
        display: flex;
        height: 44px;
        padding: 15px 0 0 0;

        > div {
          height: 44px;
          margin-right: 15px;
        }

        > .keywordView {
          > button {
            font-size: 16px;
            font-family: PingFangSC-Medium;
            color: #fff;
          }
        }
      }
    }
  }

  > .contentView {
    text-align: center;

    > .headerView {
      line-height: 40px;
      padding: 0 140px 0 25px;
      font-size: 13px;
      color: #666;
      text-align: center;
    }

    > .listView {
      // height:100vh;
      // overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 25px;

      > .listItem {
        position: relative;
        // line-height: 20px;
        font-size: 13px;
        color: #333;
        background: #f8f8f8;
        text-align: center;

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 36px;
        }
      }

      > .moreItem {
        margin-top: 5px;
        line-height: 40px;
        font-size: 13px;
        color: #3370ff;
        text-align: center;
      }

      > .listItem:nth-child(odd) {
        background: #fff;
      }
    }
  }

  > .contentView .flexView {
    display: flex;
    padding: 15px 140px 15px 25px;

    > label {
      flex: 1;
    }

    > label:nth-child(1) {
      flex: 1.2;
    }

    > label:nth-child(3) {
      flex: 1.2;
    }

  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.memberDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
       font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: '';
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }

      > .btnView {
        width: 132px;
        display: flex;

        > button {
          flex: 1;
          margin-right: 12px;
          height: 40px;
          background: #47BF7C;
          color: #fff;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          border-radius: 4px;
        }

        > button:last-child {
          margin-right: 0;
          background: #3370ff;
        }
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in .9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out .9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in .9s ease
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out .9s ease forwards
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.memberBaseInfoView {
  position: relative;
  height: 100%;
  padding-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;

  > .memberInfoView {
    display: flex;

    > div {
      width: 215px;
      height: 48px;
      margin-right: 10px;
      background: #f8f8f8;
      border-radius: 6px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      display: flex;
      overflow: hidden;

      > label {
        color: #999;
        margin-right: 10px;
      }

      > input {
        flex: 1;
        background: #f8f8f8;
        border: none;
        outline: none;
        text-align: right;
        font-size: 14px;
        color: #333;
        height: 24px;
        min-width: 0;
        padding: 0;
      }

      > input[disabled] {
        color: #333;
        -webkit-text-fill-color: #333;
        opacity: 1;
      }
    }
  }

  > .cardInfoView {
    margin-top: 30px;
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      height: 48px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .accInfoView {
    margin-top: 18px;
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      height: 48px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .btn-save {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 90px;
    line-height: 40px;
    height: 40px;
    font-size: 15px;
    color: #fff;
    border-radius: 5px;
    background: #3370ff;
    padding: 0;
  }
}

.orderListView {
  position: relative;
  text-align: center;
  padding-top: 10px;

  > .headerView {
    display: flex;
    padding-right: 50px;
    line-height: 44px;
    background: #fff;

    > label {
      flex: 1;
      font-size: 14px;
      color: #333;
    }
  }

  > .listView {
    overflow-x: hidden;
    overflow-y: auto;

    > .listItem {
      position: relative;
      display: flex;
      line-height: 20px;
      padding: 15px 50px 15px 0;
      background: #fff;

      > label {
        flex: 1;
        font-size: 14px;
        color: #333;
      }
    }

    > .listItem:nth-child(odd) {
      background: #f8f8f8;
    }
  }
}


button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in .9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out .9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in .9s ease
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out .9s ease forwards
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}

</style>
